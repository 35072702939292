import React from "react"
import { Helmet } from "react-helmet"
import url from "url"
import config from "../utils/siteConfig"

interface BlogArticleMetaProps {
  post: Queries.GhostPostFieldsFragment
  settings: Queries.GhostSettingsFieldsFragment
  location: unknown
}

const BlogArticleMeta: React.FC<BlogArticleMetaProps> = ({ post, settings, location }) => {
  const trimText = (string) => {
    const length = 160
    return string.length > length ? string.substring(0, length - 3) + "..." : string
  }

  const description = trimText(post.meta_description || post.excerpt)
  const canonical = url.resolve(config.siteUrl, location.pathname)

  const author = post.primary_author
  const authorFacebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}/` : null
  // const publicTags = (post.tags || []).filter((tag) => tag.visibility === "public").map((tag) => tag.name)
  const publicTags: string[] = []
  const primaryTag = publicTags[0] || ``
  const shareImage = post.feature_image ? post.feature_image : settings.cover_image
  const publisherLogo =
    settings.logo || config.siteIcon ? url.resolve(config.siteUrl, settings.logo || config.siteIcon) : null

  const jsonLd = {
    "@context": "http://schema.org",
    "@type": "Article",
    author: {
      "@type": `Person`,
      name: author.name,
      image: author.profile_image ?? undefined,
    },
    keywords: publicTags.length ? publicTags.join(`, `) : undefined,
    headline: post.meta_title || post.title,
    url: canonical,
    datePublished: post.published_at,
    dateModified: post.updated_at,
    image: shareImage
      ? {
          "@type": "ImageObject",
          url: shareImage,
        }
      : undefined,
    publisher: {
      "@type": "Organization",
      name: settings.title,
      logo: {
        "@type": "ImageObject",
        url: publisherLogo,
        width: 60,
        height: 60,
      },
    },
    description: description,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": config.siteUrl,
    },
  }

  return (
    <>
      <Helmet>
        <title>{post.meta_title || post.title}</title>
        <meta name="description" content={post.meta_description || post.excerpt} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.og_title || post.meta_title || post.title} />
        <meta property="og:description" content={post.og_description || post.excerpt || post.meta_description} />
        <meta property="og:description" content={post.og_description || post.excerpt || post.meta_description} />
        <meta property="og:url" content={canonical} />
        <meta property="article:published_time" content={post.published_at} />
        <meta property="article:modified_time" content={post.updated_at} />
        {publicTags.map((keyword, i) => (
          <meta property="article:tag" content={keyword} key={i} />
        ))}
        {authorFacebookUrl && <meta property="article:author" content={authorFacebookUrl} />}
        <meta name="twitter:title" content={post.twitter_title || post.meta_title || post.title} />
        <meta name="twitter:description" content={post.twitter_description || post.excerpt || post.meta_description} />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={author.name} />
        {primaryTag && <meta name="twitter:label2" content="Filed under" />}
        {primaryTag && <meta name="twitter:data2" content={primaryTag} />}
        {shareImage && <meta name="twitter:card" content="summary_large_image" />}
        {shareImage && <meta name="twitter:image" content={shareImage} />}
        {shareImage && <meta property="og:image" content={shareImage} />}
        {shareImage && <meta property="og:image:width" content={config.shareImageWidth.toString()} />}
        {shareImage && <meta property="og:image:height" content={config.shareImageHeight.toString()} />}

        {settings.twitter && (
          <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
    </>
  )
}

/** export */
export default BlogArticleMeta
