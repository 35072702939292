import React from "react"
import { graphql, navigate, PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import url from "url"
import config from "../../utils/siteConfig"
import { tags as tagsHelper } from "@tryghost/helpers"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** dynamic sections */
import BlogArticleSection from "../../components/dynamic-sections/BlogArticleSection"
import BlogArticleMeta from "../../components/BlogArticleMeta"

const colorPalette = theme.colorPalettes.blue

/** const */
const Post: React.FC<PageProps<Queries.GhostPostPageQuery>> = ({ data, location }) => {
  const post = data.ghostPost
  const settings = data.allGhostSettings.edges[0].node

  return (
    <Layout
      title="Norhart"
      description=""
      keywords="norhart, blog, property management, construction, lean, recruiting, "
      imageTwitter={`${config.siteUrl}/blog/norhart-blog-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteUrl}/blog/norhart-blog-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <BlogArticleMeta post={post} settings={settings} location={location} />
      <BlogArticleSection post={post} />
    </Layout>
  )
}

/** export */
/** export */
export default Post

export const postQuery = graphql`
  query GhostPostPage($id: String!) {
    ghostPost(id: { eq: $id }) {
      ...GhostPostFields
    }

    allGhostSettings {
      edges {
        node {
          ...GhostSettingsFields
        }
      }
    }
  }
`
