import React from "react"
import { Tags } from "@tryghost/helpers-gatsby"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import BlogAuthors from "../BlogAuthors"

/** props */
interface Props {
  post: any
}

/** const */
const BlogArticleSection: React.FC<Props> = (props) => {
  const { post } = props
  const readingTime = readingTimeHelper(post)
  return (
    <article id="post">
      <header className="container pt-5 overflow-hidden">
        <div className="row text-start pb-5">
          <div className="col-md-6 offset-md-3">
            <div className="vstack gap-2">
              <div className="article-tag hstack gap-2">
                {post.primary_tag && <span className="text-secondary">{post.primary_tag.name}</span>}
                {post.featured && <span className="text-dark">Featured</span>}
              </div>
              <h1 className="display-5 fw-bold">{post.title}</h1>
              <BlogAuthors post={post} />
            </div>
          </div>
        </div>
        {post.feature_image && (
          <figure className="figure w-100 m-0 p-0 overflow-hidden">
            <img src={post.feature_image} className="m-0 p-0 figure-img img-fluid rounded" alt={post.title} />
            {post.feature_image_caption && (
              <figcaption className="figure-caption text-center">{post.feature_image_caption}</figcaption>
            )}
          </figure>
        )}
      </header>
      <div className="post-content-wrapper">
        <section id="post-body" className="post-content" dangerouslySetInnerHTML={{ __html: post.html }}></section>
      </div>
    </article>
  )
}
/** export */
export default BlogArticleSection
